import { CollapseStoredProps } from '@vkph/components';
import { FC } from 'react';

import { BlogModel, BlogWidgetsType } from '@vkph/common/types/models';
import { routes, RouteValues } from '@vkph/common/utils';
import FileSvg from '@vkph/ui/svg/file.svg';
import UserAddSvg from '@vkph/ui/svg/user-add.svg';

import {
  BlogWidgetSearcher,
  BlogWidgetNewContentAdapter,
  BlogWidgetNewParticipants,
  BlogWidgetTopTags,
  BlogWidgetLastActivity,
  BlogWidgetTrackTagAdapter,
} from './widget';

export type BlogWidgetComponent = FC<{ blog: BlogModel }>;
export interface BlogWidgetComposition extends Pick<CollapseStoredProps, 'name' | 'header'> {
  component: BlogWidgetComponent;
  icon?: SvgrComponent;
}

export const BLOG_WIDGET_COMPOSITION: Record<BlogWidgetsType, BlogWidgetComposition> = {
  [BlogWidgetsType.NewParticipants]: {
    name: BlogWidgetsType.NewParticipants,
    header: 'Новые участники',
    icon: UserAddSvg,
    component: BlogWidgetNewParticipants,
  },
  [BlogWidgetsType.NewContent]: {
    name: BlogWidgetsType.NewContent,
    header: 'Новые материалы',
    icon: FileSvg,
    component: BlogWidgetNewContentAdapter,
  },
  [BlogWidgetsType.LastActivity]: {
    header: 'Последняя активность в группе',
    name: BlogWidgetsType.LastActivity,
    component: BlogWidgetLastActivity,
  },
  [BlogWidgetsType.ContentSearch]: {
    name: BlogWidgetsType.ContentSearch,
    header: 'Поиск материалов в группе',
    component: BlogWidgetSearcher,
  },
  [BlogWidgetsType.TopTags]: {
    name: BlogWidgetsType.TopTags,
    header: 'Популярные теги',
    component: BlogWidgetTopTags,
  },
  [BlogWidgetsType.TrackTag]: {
    name: BlogWidgetsType.TrackTag,
    header: 'Отслеживать тег',
    component: BlogWidgetTrackTagAdapter,
  },
};

export const BLOG_WIDGET_COLLAPSE_STORED_PREFIX = 'BlogWidget';

export const dynamicRenderBlogRoutes: RouteValues[] = [routes.GroupAlbums, routes.GroupAlbumsImage];
