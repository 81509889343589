import { BlogWidgetCompoundFileList } from './file-list/BlogWidgetCompoundFileList';
import { BlogWidgetCompoundPostList } from './post-list/BlogWidgetCompoundPostList';
import { BlogWidgetCompoundTabSection } from './tab-section/BlogWidgetCompoundTabSection';

type BlogWidgetCompoundComposition = {
  TabSection: typeof BlogWidgetCompoundTabSection;
  PostList: typeof BlogWidgetCompoundPostList;
  FileList: typeof BlogWidgetCompoundFileList;
};

export const BlogWidgetCompound: BlogWidgetCompoundComposition = {
  TabSection: BlogWidgetCompoundTabSection,
  PostList: BlogWidgetCompoundPostList,
  FileList: BlogWidgetCompoundFileList,
};
