import { UiButton, UiButtonProps, UiIcon, UiRow, UiTag, message } from '@vkph/ui';
import { stringify } from 'query-string';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getFileStorageSearchListStorage } from '@vkph/common/store/filestorage';
import { openGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';
import { getTagsListStorage } from '@vkph/common/store/tags';
import { OrderingParams } from '@vkph/common/types';
import {
  BlogId,
  FileStorageEntryId,
  SearchCategoryType,
  SearchSingleCategory,
  Tag,
  TagRelation,
  TagRelationObjectType,
} from '@vkph/common/types/models';
import { getErrorResponseMessage, getRoutePath, RouteNames } from '@vkph/common/utils';
import { useTheme } from '@vkph/ui/providers/theme';
import CloseSVG from '@vkph/ui/svg/close.svg';
import { BlogWidgetCompound } from '~groups/components/blog/widget/compound/BlogWidgetCompound';
import { getBlogPostsStorage } from '~groups/store/blog';

const RECORDS_TO_SHOW = 3;

type Props = {
  blogId: BlogId;
  fileStorageId: FileStorageEntryId;
};

export const BlogWidgetTrackTag: FC<Props> = (props) => {
  const { blogId, fileStorageId } = props;
  const postsStorage = useMemo(() => getBlogPostsStorage(blogId), [blogId]);
  const filesStorage = useMemo(() => getFileStorageSearchListStorage(fileStorageId), [fileStorageId]);
  const { storage: tagsListStorage, followTagsEffect } = useMemo(getTagsListStorage, []);

  const navigate = useNavigate();
  const [{ variables: themeVariables }] = useTheme();

  const serviceObjects = useMemo<TagRelation[]>(() => {
    const result: TagRelation[] = [
      {
        objectId: blogId,
        objectType: TagRelationObjectType.Blog,
      },
    ];

    if (fileStorageId) {
      result.push({
        objectId: fileStorageId,
        objectType: TagRelationObjectType.StorageObject,
      });
    }

    return result;
  }, [blogId, fileStorageId]);

  const { data: followedTags } = useAbstractStorage(tagsListStorage, {
    autoFetchParams: {
      ordering: [OrderingParams.NameAtDesc],
      serviceObjects,
      isFollowing: true,
    },
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
  });

  const isHasFollowedTags = Boolean(followedTags.length > 0);

  const tagsSearchParams = useMemo<string>(() => {
    return followedTags.map((tag) => `@[${tag.name}](tag/${tag.id})`).join(' ');
  }, [followedTags]);

  const { data: posts, loading: postsLoading } = useAbstractStorage(postsStorage.storage, {
    autoFetchAndRefetch: isHasFollowedTags,
    autoFetchParams: {
      ordering: OrderingParams.CreatedAtDesc,
      pageSize: RECORDS_TO_SHOW,
      search: tagsSearchParams,
    },
    cancelPendingRequestOnUnmount: true,
  });

  const { data: files, loading: filesLoading } = useAbstractStorage(filesStorage.storage, {
    autoFetchAndRefetch: isHasFollowedTags,
    autoFetchParams: {
      ordering: [OrderingParams.CreatedAtDesc],
      pageNumber: 1,
      pageSize: RECORDS_TO_SHOW,
      search: tagsSearchParams,
    },
    cancelPendingRequestOnUnmount: true,
  });

  const onFollowTags = (tags: Tag[]) => {
    const tagIds = tags.map((tag) => tag.id);

    followTagsEffect({ tagIds }).catch((e) =>
      message.error(getErrorResponseMessage(e, 'Не удалось изменить состояние тега')),
    );
  };

  const onTagDeselect = (tag: Tag) => {
    const newFollowedTags = followedTags.filter((followedTag) => followedTag.id !== tag.id);

    onFollowTags(newFollowedTags);
  };

  const onOpenTagsList = () => {
    openGlobalModal(GlobalModalNames.TagList, {
      relations: serviceObjects,
      onSelect: onFollowTags,
      value: followedTags,
    });
  };

  const onOpenSearch = (category: SearchCategoryType) => {
    navigate({
      pathname: getRoutePath(RouteNames.GroupSearch, { id: blogId }),
      search: stringify({ category, query: tagsSearchParams }),
    });
  };

  const showAllButtonProps: UiButtonProps = {
    type: 'link',
    label: 'Все',
    style: { marginRight: 24 },
  };

  return (
    <>
      <UiRow
        justify={followedTags.length > 0 ? 'start' : 'center'}
        gutter={[0, 8]}
        style={{ padding: '0 24px 16px 24px' }}
        align="middle"
      >
        {followedTags.map((tag) => (
          <UiTag
            key={tag.id}
            style={{ paddingTop: 6 }}
            title={tag.name}
            color={themeVariables.colorBrand08}
            textColor={themeVariables.colorTextPrimary}
          >
            <UiButton
              type="link-secondary"
              onClick={() => onTagDeselect(tag)}
              icon={<UiIcon component={CloseSVG} width={20} height={20} />}
            />
          </UiTag>
        ))}

        <UiButton type="link" label="Выбрать теги" onClick={onOpenTagsList} />
      </UiRow>

      <>
        {postsLoading && <BlogWidgetCompound.TabSection.Skeleton records={RECORDS_TO_SHOW} />}
        {isHasFollowedTags && !postsLoading && posts.length > 0 && (
          <BlogWidgetCompound.TabSection
            title="Новые посты по тегам"
            extra={
              <UiButton onClick={() => onOpenSearch(SearchSingleCategory.Post)} {...showAllButtonProps} />
            }
          >
            <BlogWidgetCompound.PostList compact posts={posts} contentLines={1} />
          </BlogWidgetCompound.TabSection>
        )}
      </>

      <>
        {filesLoading && <BlogWidgetCompound.TabSection.Skeleton records={RECORDS_TO_SHOW} />}
        {isHasFollowedTags && !filesLoading && files.length > 0 && (
          <BlogWidgetCompound.TabSection
            title="Новые файлы по тегам"
            extra={
              <UiButton onClick={() => onOpenSearch(SearchSingleCategory.Files)} {...showAllButtonProps} />
            }
          >
            <BlogWidgetCompound.FileList compact files={files} />
          </BlogWidgetCompound.TabSection>
        )}
      </>
    </>
  );
};
