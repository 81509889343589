import { Achievement } from '@vkph/components';
import {
  UiModal,
  UiModalContext,
  message,
  UiList,
  UiInput,
  UiForm,
  UiButton,
  UiSpace,
  UiTooltip,
  UiTooltipPlacement,
  UiSkeleton,
  UiInputNumber,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo, useState, FC, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { useCurrentProfile } from '@vkph/common/providers/current-profile';
import { UserIdModel, GamificationThanksBase } from '@vkph/common/types/models';
import { createArrayMock, MAX_LENGTH_INPUT_300, coinsDeclension, declension } from '@vkph/common/utils';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';
import {
  GamificationUserThanksSummaryStorage,
  getGamificationThanksStorage,
  getGamificationWalletStorage,
} from '~profile/store/gamification';

const MOCKS_COUNT = 6;
const addThanksMocks = createArrayMock(MOCKS_COUNT, (_, key) => (
  <UiSkeleton key={key} circle loading width={76} height={76} style={{ margin: '0px 16px 16px 0px' }} />
));

type Props = {
  userThanksStorage: GamificationUserThanksSummaryStorage;
};

type UserProfileParams = {
  id: UserIdModel;
};

export const AddThanks: FC<Props> = (props) => {
  const [form] = UiForm.useForm();
  const messageValue = UiForm.useWatch('message', form);
  const { onClose } = useContext(UiModalContext);

  const { id: userProfile = '' } = useParams<UserProfileParams>();
  const { userThanksStorage } = props;
  const profile = useCurrentProfile();
  const createThanksPending = useStore(userThanksStorage.createThanksEffect.pending);
  const [selectedThank, setSelectedThank] = useState<GamificationThanksBase | null>(null);

  const thanksStorage = useMemo(() => getGamificationThanksStorage(), [profile.id]);
  const { data: thanks, loading: isThanksLoading } = useAbstractStorage(thanksStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const userWalletStorage = useMemo(() => getGamificationWalletStorage({ userId: profile.id }), [profile.id]);

  const { data: userWallet } = useAbstractStorage(userWalletStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  useEffect(() => setSelectedThank(thanks[0] || null), [thanks]);

  const showThanks = !isThanksLoading && thanks.length > 0;
  const messageMaxInput = `${messageValue?.length || 0} из ${MAX_LENGTH_INPUT_300}`;

  const sendThanks = ({ coins }: { coins: number }) => {
    if (selectedThank) {
      const createData = {
        msg: messageValue?.trim(),
        toUser: userProfile,
        typeRid: selectedThank.id,
        coins: coins || 0,
      };

      userThanksStorage
        .createThanksEffect(createData)
        .then(onClose)
        .catch((e) => {
          message.error(e?.response?.data?.detail?.toString() || 'Ошибка');

          thanksStorage.storage.refetchWithLastParams();
          userWalletStorage.storage.refetchWithLastParams();
        });
    }
  };

  const maxCoinsCount = parseInt(userWallet?.balance || '', 10) || undefined;
  const maxCoinsCountLabel = maxCoinsCount
    ? `У вас ${maxCoinsCount} ${declension(maxCoinsCount, coinsDeclension)}`
    : 'У вас нет койнов';

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title
          steps={getModalStepsForSingleTitle(selectedThank?.attributes?.name || 'Поблагодарить')}
        />
      </UiModal.Header>

      <UiModal.Content style={{ padding: '24px 24px 0' }}>
        <UiSpace size={0}>
          {isThanksLoading && addThanksMocks}
          {showThanks && (
            <UiList
              split={false}
              grid={{ gutter: 12 }}
              dataSource={thanks}
              renderItem={(thank) => {
                const { id, attributes } = thank;
                const isSelected = Boolean(id === selectedThank?.id);

                return (
                  <UiTooltip placement={UiTooltipPlacement.Bottom} title={attributes.name}>
                    <UiList.Item onClick={() => !createThanksPending && setSelectedThank(thank)}>
                      <Achievement.Icon
                        type="thank"
                        size="middle"
                        src={attributes.image}
                        isSelected={isSelected}
                      />
                    </UiList.Item>
                  </UiTooltip>
                );
              }}
            />
          )}
        </UiSpace>

        <UiForm form={form} onFinish={sendThanks} layout="vertical" size="large">
          <UiForm.Item label="Сообщение" name="message" initialValue="" extra={messageMaxInput}>
            <UiInput.TextArea maxLength={MAX_LENGTH_INPUT_300} disabled={createThanksPending} />
          </UiForm.Item>

          <UiForm.Item label="Перевести коины" initialValue={0} name="coins" extra={maxCoinsCountLabel}>
            <UiInputNumber
              max={maxCoinsCount}
              style={{ width: 124 }}
              disabled={!maxCoinsCount || createThanksPending}
            />
          </UiForm.Item>
        </UiForm>
      </UiModal.Content>

      <UiModal.Footer>
        <UiModal.Footer.Buttons>
          <UiButton
            size="large"
            type="primary"
            label="Поблагодарить"
            onClick={form.submit}
            disabled={createThanksPending}
            loading={createThanksPending}
          />
          <UiButton size="large" label="Отмена" onClick={onClose} />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};
