import { UiSpace, UiDivider, UiSkeleton } from '@vkph/ui';
import React, { FC } from 'react';

import { createArrayMock } from '@vkph/common/utils';

type Props = {
  records?: number;
};

export const BlogWidgetCompoundTabSectionSkeleton: FC<Props> = (props) => {
  const { records = 3 } = props;

  const RecordSkeleton: FC = () => {
    return (
      <UiSpace full>
        <UiSkeleton loading circle width={40} height={40} />
        <UiSpace size={0} direction="vertical">
          <UiSkeleton loading width={200} height={20} />
          <UiSkeleton loading width={150} height={20} />
        </UiSpace>
      </UiSpace>
    );
  };

  return (
    <>
      <UiDivider emptyMargin />
      <UiSpace style={{ padding: 24 }} size={20} direction="vertical" full>
        <UiSkeleton loading width={100} height={20} />
        {createArrayMock(records, (_, key) => (
          <RecordSkeleton key={key} />
        ))}
      </UiSpace>
    </>
  );
};
