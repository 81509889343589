import { UiBadge, UiDivider, UiCol, UiRow, UiSpace, UiTypography } from '@vkph/ui';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { BlogWidgetCompoundTabSectionSkeleton } from './skeleton/BlogWidgetCompoundTabSectionSkeleton';

type BlogWidgetCompoundTabSectionComposition = {
  Skeleton: typeof BlogWidgetCompoundTabSectionSkeleton;
};

export type Props = {
  title: string;
  recordCount?: number;
  extra?: ReactNode;
  compact?: boolean;
};

type BlogWidgetCompoundTabSectionComponent = FC<PropsWithChildren<Props>> &
  BlogWidgetCompoundTabSectionComposition;

export const BlogWidgetCompoundTabSection: BlogWidgetCompoundTabSectionComponent = (props) => {
  const { title, recordCount, children, compact = false, extra } = props;

  return (
    <>
      {compact && <UiDivider emptyMargin />}
      <UiSpace style={{ paddingBottom: 16 }} direction="vertical" size={20} full>
        <UiRow style={{ padding: '20px 24px 0 24px' }} justify="space-between">
          <UiCol>
            <UiSpace size={8} align="center">
              <UiTypography.Title level={3} style={{ margin: 0 }}>
                {title}
              </UiTypography.Title>
              {recordCount && <UiBadge count={recordCount} />}
            </UiSpace>
          </UiCol>
          {extra}
        </UiRow>
        {children}
      </UiSpace>
    </>
  );
};

BlogWidgetCompoundTabSection.Skeleton = BlogWidgetCompoundTabSectionSkeleton;
