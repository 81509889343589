import { UiEmpty } from '@vkph/ui';
import React from 'react';

import { BlogWidgetComponent } from '../../../constants';
import { BlogWidgetTrackTag } from '../BlogWidgetTrackTag';

export const BlogWidgetTrackTagAdapter: BlogWidgetComponent = (props) => {
  const { blog } = props;
  const { fileStorageRootFolderId, id } = blog;

  if (!fileStorageRootFolderId) {
    return <UiEmpty.Feed emptyMessage={{ header: 'Файловое хранилище группы не найдено' }} />;
  }

  return <BlogWidgetTrackTag blogId={id} fileStorageId={fileStorageRootFolderId} />;
};
