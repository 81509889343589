import { UiButton, UiCard } from '@vkph/ui';
import { stringify } from 'query-string';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryParams } from '@vkph/common/hooks';
import { getFileStorageSearchListStorage } from '@vkph/common/store/filestorage';
import { BaseFieldParams } from '@vkph/common/types';
import { BlogModel, FileStorageEntryId, SearchCategoryType } from '@vkph/common/types/models';
import { getRoutePath, RouteNames } from '@vkph/common/utils';
import { SearchCategory } from '@vkph/modules/store/search/advanced-search';
import { AbsolutePlacement } from '@vkph/ui/hooks';
import { getBlogPostsStorage } from '~groups/store/blog';

import { BLOG_WIDGET_SEARCHER_INPUT_HEIGHT } from '../constants';
import { BlogWidgetSearcherContentResults } from './results/BlogWidgetSearcherContentResults';
import { BlogWidgetSearcherContentTabs } from './tabs/BlogWidgetSearcherContentTabs';

type Props = {
  blog: BlogModel;
  fileStorageId: FileStorageEntryId;
};

type BlogWidgetSearcherContentComposition = {
  Results: typeof BlogWidgetSearcherContentResults;
  Tabs: typeof BlogWidgetSearcherContentTabs;
};

type BlogWidgetSearcherContentComponent = FC<Props> & BlogWidgetSearcherContentComposition;
type BlogWidgetSearcherContentCategory = {
  category: SearchCategoryType;
};

type BlogWidgetSearcherContentQueryParams = BlogWidgetSearcherContentCategory &
  Pick<BaseFieldParams, 'search'>;

export const BlogWidgetSearcherContent: BlogWidgetSearcherContentComponent = (props) => {
  const {
    blog: { id, slug },
    fileStorageId,
  } = props;
  const navigate = useNavigate();
  const {
    queryParams: { category = SearchCategory.All, search },
  } = useQueryParams<BlogWidgetSearcherContentQueryParams>();

  const postsStorage = useMemo(() => getBlogPostsStorage(id), [id]);
  const filesStorage = useMemo(() => getFileStorageSearchListStorage(fileStorageId), [fileStorageId]);

  const onNavigateToAdvancedSearch = () =>
    navigate({
      pathname: getRoutePath(RouteNames.GroupSearch, { id: slug || id }),
      search: stringify({
        category,
        search,
      }),
    });

  return (
    <UiCard.Overlay
      bordered
      placement={AbsolutePlacement.TopLeft}
      shift={{
        top: BLOG_WIDGET_SEARCHER_INPUT_HEIGHT,
      }}
    >
      <BlogWidgetSearcherContentTabs
        blogId={id}
        tabBarExtraContent={{
          right: (
            <UiButton type="link" onClick={onNavigateToAdvancedSearch} style={{ marginRight: 24 }}>
              Расширенный поиск
            </UiButton>
          ),
        }}
      />
      <BlogWidgetSearcherContentResults
        {...props}
        blogId={id}
        postsStorage={postsStorage}
        filesStorage={filesStorage}
        compact
      />
    </UiCard.Overlay>
  );
};

BlogWidgetSearcherContent.Results = BlogWidgetSearcherContentResults;
BlogWidgetSearcherContent.Tabs = BlogWidgetSearcherContentTabs;
