import { UiCol, UiRow } from '@vkph/ui';
import React, { useRef, useState } from 'react';

import { useQueryParams } from '@vkph/common/hooks';
import { BaseFieldParams } from '@vkph/common/types';
import { SearchCategoryType } from '@vkph/common/types/models';
import { useOnClickOutside } from '@vkph/ui/hooks';
import { BlogWidgetComponent } from '~groups/components/blog/constants';

import { BlogWidgetSearcherContent } from './content/BlogWidgetSearcherContent';
import { BlogWidgetSearcherInput } from './input/BlogWidgetSearcherInput';

interface BlogWidgetSearcherComposition extends BlogWidgetComponent {
  Input: typeof BlogWidgetSearcherInput;
  Content: typeof BlogWidgetSearcherContent;
}

interface BlogWidgetSearcherParams extends Pick<BaseFieldParams, 'search'> {
  category?: SearchCategoryType;
}

export const BlogWidgetSearcher: BlogWidgetSearcherComposition = (props) => {
  const { blog } = props;
  const { id, fileStorageRootFolderId, fileStorageSubFolders } = blog;
  const {
    setQueryParams,
    queryParams: { search, category },
  } = useQueryParams<Partial<BlogWidgetSearcherParams>>();
  const [isSearchOverlayOpen, setIsSearchOverlayOpen] = useState(Boolean(search || category));

  const fileStorageId = fileStorageSubFolders?.files;

  const searcherRef = useRef<HTMLDivElement>(null);

  const onFocus = () => setIsSearchOverlayOpen(true);

  useOnClickOutside(searcherRef, () => {
    setIsSearchOverlayOpen(false);
  });

  const onClickSearch = (searchString: string) => {
    setQueryParams({ search: searchString });
  };

  return (
    <UiRow>
      <UiCol ref={searcherRef} span={24}>
        {fileStorageRootFolderId && (
          <BlogWidgetSearcherInput
            blogId={id}
            fileStorageId={fileStorageRootFolderId}
            onClick={onClickSearch}
            onFocus={onFocus}
          />
        )}
        {isSearchOverlayOpen && fileStorageId && (
          <BlogWidgetSearcherContent blog={blog} fileStorageId={fileStorageId} />
        )}
      </UiCol>
    </UiRow>
  );
};

BlogWidgetSearcher.Input = BlogWidgetSearcherInput;
BlogWidgetSearcher.Content = BlogWidgetSearcherContent;
