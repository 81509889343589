import { WidgetUuid } from '@vkph/widget-sdk';
import pickBy from 'lodash/pickBy';
import React, { CSSProperties, IframeHTMLAttributes, forwardRef, useEffect, LegacyRef } from 'react';

import { registry } from '../registry';

type BaseProps = Pick<IframeHTMLAttributes<HTMLIFrameElement>, 'style'>;

export interface WidgetProps extends BaseProps {
  url: string;
  uuid: WidgetUuid;
  onLoad?: (uuid: WidgetUuid) => void;
  onError?: (uuid: WidgetUuid) => void;
}

export const Widget = forwardRef<HTMLElement, WidgetProps>((props, ref) => {
  const { url, uuid, onLoad, onError, style } = props;

  const onLoadIFrame = () => {
    onLoad?.(uuid);
  };

  const onErrorIFrame = () => {
    onError?.(uuid);
  };

  useEffect(() => {
    registry.add(uuid);

    return () => {
      registry.delete(uuid);
    };
  }, []);

  const mergedStyle: CSSProperties = {
    width: '100%',
    // TODO: B2BCORE-11422
    height: '720px',
    border: 'none',
    ...pickBy(style, (value) => Boolean(value)),
  };

  return (
    <iframe
      title=" "
      name={uuid}
      src={url}
      id={`uuid-${uuid}`}
      style={mergedStyle}
      onLoad={onLoadIFrame}
      onError={onErrorIFrame}
      ref={ref as LegacyRef<HTMLIFrameElement>}
    />
  );
});
