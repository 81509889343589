import { PostCompound, PostCompoundSearchViewProps } from '@vkph/modules';
import { UiList } from '@vkph/ui';
import React, { FC, useCallback } from 'react';

import { useLongreadNavigation } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types';
import { PostBasicModel, PostId, PostTypes } from '@vkph/common/types/models';

interface Props extends Omit<PostCompoundSearchViewProps, 'post'> {
  posts: PostBasicModel[];
}

export const BlogWidgetCompoundPostList: FC<Props> = (props) => {
  const { posts, ...restProps } = props;
  const { openLongread } = useLongreadNavigation();

  const onOpenLongread = useCallback(
    (postId: PostId) => {
      openLongread({ id: postId, type: LongreadTypes.Post, postType: PostTypes.Entry });
    },
    [openLongread],
  );

  return (
    <UiList
      split={false}
      dataSource={posts}
      renderItem={(post) => (
        <UiList.Item hoverable style={{ padding: '8px 0' }} onClick={() => onOpenLongread(post.id)}>
          <PostCompound.SearchView {...restProps} post={post} />
        </UiList.Item>
      )}
    />
  );
};
