import { CropImageParams } from '@vkph/components';
import { AxiosResponse } from 'axios';

import { endpoints } from '@vkph/common/endpoints';
import { UserIdModel, FileStorageEntryId, ProfileUserSettings } from '@vkph/common/types/models';
import { api } from '@vkph/common/utils';

export const getProfileUserSettings = () => endpoints.profiles.userSettings();

export type ProfileUserAvatarUploadParams = {
  userId: UserIdModel;
  fileId: FileStorageEntryId;
  settings: {
    medium: CropImageParams;
    small: CropImageParams;
  };
};

export const profileUserAvatarUpload = <T = unknown>({
  userId,
  fileId,
  settings,
}: ProfileUserAvatarUploadParams) =>
  api.post<T>({
    url: endpoints.profiles.v3AvatarCreate(userId),
    data: { fileId, settings },
  });

export const postProfileUserSettings = <T = unknown>(
  params: Partial<ProfileUserSettings>,
): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.profiles.userSettings(),
    data: { settings: { ...params } },
  });
