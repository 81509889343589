import { OrderSwitcher } from '@vkph/components';
import { UiEmpty, UiTabItem, UiTabs, UiTooltipPlacement } from '@vkph/ui';
import React, { FC, useMemo, useState } from 'react';

import { useAbstractStorage, useLocalStorage } from '@vkph/common/hooks';
import { getFileStorageSearchListStorage } from '@vkph/common/store/filestorage';
import { OrderingParams } from '@vkph/common/types';
import {
  BlogId,
  FileStorageEntryId,
  SearchAllCategory,
  SearchCategoryType,
  SearchSingleCategory,
} from '@vkph/common/types/models';
import { getBlogPostsStorage } from '~groups/store/blog';

import { BlogWidgetCompound } from '../compound/BlogWidgetCompound';

const RECORDS_TO_SHOW = 5;
const NEW_CONTENT_CATEGORY_TABS: UiTabItem<SearchCategoryType>[] = [
  {
    key: SearchAllCategory.All,
    label: 'Все материалы',
  },
  {
    key: SearchSingleCategory.Post,
    label: 'Посты',
  },
  {
    key: SearchSingleCategory.Files,
    label: 'Файлы',
  },
];

type BlogWidgetNewContentOrdering = OrderingParams.CreatedAtAsc | OrderingParams.CreatedAtDesc;

type Props = {
  blogId: BlogId;
  fileStorageId: FileStorageEntryId;
};

export const BlogWidgetNewContent: FC<Props> = (props) => {
  const { blogId, fileStorageId } = props;
  const [searchCategory, setSearchCategory] = useLocalStorage<SearchCategoryType>(
    'blogWidgetNewContentSearchCategory',
    SearchAllCategory.All,
  );

  const [postsOrdering, setPostsOrdering] = useState<BlogWidgetNewContentOrdering>(
    OrderingParams.CreatedAtDesc,
  );
  const [filesOrdering, setFilesOrdering] = useState<BlogWidgetNewContentOrdering>(
    OrderingParams.CreatedAtDesc,
  );

  const postsStorage = useMemo(() => getBlogPostsStorage(blogId), [blogId]);
  const filesStorage = useMemo(() => getFileStorageSearchListStorage(fileStorageId), [fileStorageId]);

  const isAllCategories = searchCategory === SearchAllCategory.All;
  const isShowPosts = isAllCategories || searchCategory === SearchSingleCategory.Post;
  const isShowFiles = isAllCategories || searchCategory === SearchSingleCategory.Files;

  const { data: posts, loading: isPostsLoading } = useAbstractStorage(postsStorage.storage, {
    autoFetchAndRefetch: isShowPosts,
    autoFetchParams: {
      ordering: OrderingParams.CreatedAtDesc,
      pageSize: RECORDS_TO_SHOW,
    },
    cancelPendingRequestOnUnmount: true,
  });

  const { data: files, loading: isFilesLoading } = useAbstractStorage(filesStorage.storage, {
    autoFetchAndRefetch: isShowFiles,
    autoFetchParams: {
      ordering: [OrderingParams.CreatedAtDesc],
      pageNumber: 1,
      pageSize: RECORDS_TO_SHOW,
    },
    cancelPendingRequestOnUnmount: true,
  });

  const isEmptyPosts = !posts.length;
  const isEmptyFiles = !files.length;
  const isLoading = isPostsLoading || isFilesLoading;

  const getOrderedArray = <T,>(arr: T[], ordering: OrderingParams) => {
    return ordering === OrderingParams.CreatedAtDesc ? arr : [...arr].reverse();
  };

  const postsOrdered = useMemo(() => getOrderedArray(posts, postsOrdering), [posts, postsOrdering]);
  const filesOrdered = useMemo(() => getOrderedArray(files, filesOrdering), [files, filesOrdering]);

  const getOrderingParamsByOrderingValue = (isToggled: boolean) => {
    return isToggled ? OrderingParams.CreatedAtDesc : OrderingParams.CreatedAtAsc;
  };

  return (
    <>
      <UiTabs
        defaultActiveKey={searchCategory}
        activeKey={searchCategory}
        items={NEW_CONTENT_CATEGORY_TABS}
        onChange={(key) => {
          setSearchCategory(key as SearchCategoryType);
        }}
      />

      {isShowPosts && (
        <>
          {isPostsLoading && <BlogWidgetCompound.TabSection.Skeleton records={RECORDS_TO_SHOW} />}
          {!isPostsLoading && !isEmptyPosts && (
            <BlogWidgetCompound.TabSection
              compact
              title="Последние посты"
              extra={
                <OrderSwitcher
                  tooltipTitle="По дате"
                  value={postsOrdering === OrderingParams.CreatedAtDesc}
                  onChange={(isToggled) => setPostsOrdering(getOrderingParamsByOrderingValue(isToggled))}
                />
              }
            >
              <BlogWidgetCompound.PostList
                posts={postsOrdered}
                compact
                tooltipPlacement={UiTooltipPlacement.Left}
              />
            </BlogWidgetCompound.TabSection>
          )}
        </>
      )}

      {isShowFiles && (
        <>
          {isFilesLoading && <BlogWidgetCompound.TabSection.Skeleton records={RECORDS_TO_SHOW} />}
          {!isFilesLoading && !isEmptyFiles && (
            <BlogWidgetCompound.TabSection
              compact
              title="Последние файлы"
              extra={
                <OrderSwitcher
                  tooltipTitle="По дате"
                  value={filesOrdering === OrderingParams.CreatedAtDesc}
                  onChange={(isToggled) => setFilesOrdering(getOrderingParamsByOrderingValue(isToggled))}
                />
              }
            >
              <BlogWidgetCompound.FileList files={filesOrdered} compact />
            </BlogWidgetCompound.TabSection>
          )}
        </>
      )}

      {isEmptyPosts && isEmptyFiles && !isLoading && (
        <UiEmpty.Feed emptyMessage={{ header: 'Нет материалов' }} />
      )}
    </>
  );
};
