import vCardsJS from 'vcards-js';

import {
  ContactLink,
  GroupMemberStructureInfo,
  ContactsModel,
  ProfileInfoModel,
} from '@vkph/common/types/models';

type SocialLinks = Required<Omit<ContactLink, 'kind' | 'typeName'>>;
type VCardSocial = {
  type: string;
  value: string;
  link: string;
};

export const getVcardProfileContacts = (
  { main }: ProfileInfoModel,
  structureInfo: GroupMemberStructureInfo,
  contacts: ContactsModel,
) => {
  const vCard = vCardsJS();

  vCard.firstName = main.fullName.firstName;
  vCard.middleName = main.fullName.patronymic;
  vCard.lastName = main.fullName.lastName;
  vCard.organization = structureInfo?.organization || '';

  vCard.email = main.email || '';
  vCard.workEmail = contacts.workEmail;

  vCard.cellPhone = main.mobilePhone || '';
  vCard.workPhone = [main.workPhone || '', main.workMobilePhone || ''];

  vCard.note = main.workAddress ? `Рабочий адрес: ${main.workAddress}` : '';

  vCard.title = main.position?.name || '';
  vCard.birthday = new Date(main.birthDate);

  const validSocials = contacts.externalLinks.filter(({ prefix }) => prefix) as SocialLinks[];
  const vCardSocial: VCardSocial[] = validSocials.map(({ name, value, prefix, placeholder }) => ({
    type: name,
    value,
    link: `${prefix || placeholder}${value}`,
  }));

  vCardSocial.forEach(({ type, value }) => {
    vCard.socialUrls[type] = value;
  });

  const normalizeVCardSocialValue = (value: string) => {
    let normalizedValue = value;

    vCardSocial.forEach(({ type, value: socialValue, link }) => {
      normalizedValue = normalizedValue.replace(
        `CHARSET=UTF-8;TYPE=${type}:${socialValue}`,
        `type=${type};x-user=${socialValue}:https://${link}`,
      );
    });

    return normalizedValue;
  };

  return normalizeVCardSocialValue(vCard.getFormattedString());
};
