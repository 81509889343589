import { UiCard, UiEmpty, UiRender, UiRenderType } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { SearchAllCategory, SearchCategoryType } from '@vkph/common/types/models';
import { SearchCategory } from '@vkph/modules/store/search/advanced-search';
import { BlogWidgetCompound } from '~groups/components/blog/widget/compound/BlogWidgetCompound';

const categoryEmptyMessageMap: Partial<Record<SearchCategoryType, string>> = {
  [SearchCategory.Post]: 'Посты не найдены',
  [SearchCategory.Files]: 'Файлы не найдены',
};

type BlogSearchListWrapperProps = {
  isLoading: boolean;
  isEmpty: boolean;
  category: SearchCategoryType;
  compact?: boolean;
};

export const BlogWidgetSearcherContentResultsWrapper: FC<PropsWithChildren<BlogSearchListWrapperProps>> = (
  props,
) => {
  const { isLoading, isEmpty, category, compact = false, children } = props;

  const isAllCategories = category === SearchAllCategory.All;
  const isFeedEmpty = !compact && !isLoading && isEmpty && !isAllCategories;
  const isShowChildren = !isLoading && !isEmpty && Boolean(children);

  const isVisible = isShowChildren || isFeedEmpty || isLoading;

  return (
    <UiRender type={UiRenderType.NullElement} visible={isVisible}>
      <UiCard size="default" emptyPadding>
        {isFeedEmpty && (
          <UiEmpty.Feed
            emptyMessage={{
              header: `${categoryEmptyMessageMap[category]}`,
              description: 'Попробуйте сформулировать запрос по-другому',
            }}
          />
        )}
        {isLoading && <BlogWidgetCompound.TabSection.Skeleton />}
        {isShowChildren && children}
      </UiCard>
    </UiRender>
  );
};
