import { UiAvatar, UiButton, UiCol, UiRow, UiSkeleton, UiSpace, UiTypography } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { FC, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { UserIdModel } from '@vkph/common/types/models';
import { useSpace } from '@vkph/ui/hooks';
import { relationLabel } from '~profile/components/constants';
import { SocialsInfoType, getFollowListStorage } from '~profile/store/timelines';

const USER_TO_SHOW = 2;
const AVATARS_MOCK = <UiSkeleton loading height={40} width={56} />;

type Props = {
  userId: UserIdModel;
  count: number;
  type: SocialsInfoType;
  onClick: () => void;
};

export const RelationButton: FC<Props> = (props) => {
  const { count, userId, type, onClick } = props;
  const { spaceXS } = useSpace();

  const { storage: followListStorage } = useMemo(getFollowListStorage, []);
  const { loading: isUsersListLoading } = useAbstractStorage(followListStorage, {
    autoFetchParams: { pageNumber: 1, pageSize: USER_TO_SHOW, userId, type },
    autoFetchAndRefetch: true,
  });

  const usersAvatars = useStoreMap(followListStorage.store, ({ data }) =>
    Array.from(data).map(({ id, smallAvatar: src }) => ({ id, src })),
  );

  return (
    <UiButton.Decorator onClick={onClick}>
      <UiRow gutter={[spaceXS, 0]} align="middle">
        <UiCol flex="1">
          <UiSpace size={0} direction="vertical">
            <UiTypography.Title level={4} style={{ margin: 0 }}>
              {count}
            </UiTypography.Title>
            <UiTypography.Text type="secondary">{relationLabel[type]}</UiTypography.Text>
          </UiSpace>
        </UiCol>
        <UiCol>
          {isUsersListLoading && AVATARS_MOCK}
          {!isUsersListLoading && usersAvatars.length === 2 && (
            <UiAvatar.Group max={{ count: USER_TO_SHOW }} size="small">
              {usersAvatars.map(({ id, src }) => (
                <UiAvatar key={id} src={src} />
              ))}
            </UiAvatar.Group>
          )}
          {!isUsersListLoading && usersAvatars.length === 1 && (
            <UiAvatar size="small" bordered src={usersAvatars[0].src} />
          )}
        </UiCol>
      </UiRow>
    </UiButton.Decorator>
  );
};
