import { ActionsDropdown, ActionsDropdownItem } from '@vkph/components';
import { message, UiSpace, useModalBase } from '@vkph/ui';
import React, { useCallback, useMemo } from 'react';

import { removeMemberFromBlogEffect } from '@vkph/common/store/blogs';
import { Blog } from '@vkph/common/types';
import { BlogId } from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';

type Props = {
  blogId: BlogId;
  blogMember: Blog.Member;
};

export const MembersListActions: React.FC<Props> = (props) => {
  const { blogMember, blogId } = props;
  const { confirm } = useModalBase();

  const confirmDelete = useCallback(
    (userId: string) => {
      const modal = confirm({
        autoFocusButton: null,
        title: 'Удалить из участников?',
        okText: 'Удалить',
        cancelText: 'Отмена',
      });

      modal.update({
        onOk: (close) => {
          modal.update({ disabled: true });

          removeMemberFromBlogEffect({ userId, blogId })
            .then(() => message.success('Участник исключен из группы'))
            .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка исключения участника')))
            .finally(close);
        },
      });
    },
    [blogId],
  );

  const dropdownActions = useMemo<ActionsDropdownItem[]>(() => {
    return [
      {
        label: 'Удалить из участников',
        onClick: () => confirmDelete(blogMember.keycloakId),
      },
    ];
  }, []);

  return (
    <UiSpace style={{ padding: '0 24px' }}>
      <ActionsDropdown trigger={['click']} placement="bottom" arrow items={dropdownActions} />
    </UiSpace>
  );
};
