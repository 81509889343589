import { PaginatedList } from '@vkph/components';
import { UiPaginationConfig } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { BlogMembersStorage } from '@vkph/common/store/blogs';
import { BlogModel } from '@vkph/common/types/models';

import { BlogMembersList } from '../list/members/BlogMembersList';

type Props = {
  storage: BlogMembersStorage;
  roles: string;
  search?: string;
  blog: BlogModel;
  paginationConfig: UiPaginationConfig;
};

export const BlogMembersPaginatedList: FC<Props> = (props) => {
  const { blog, storage, roles, search, paginationConfig } = props;

  const query = useMemo(() => {
    return {
      search,
      roles,
    };
  }, [search, roles]);

  return (
    <PaginatedList<BlogMembersStorage['storage']>
      storage={storage.storage}
      query={query}
      pageSize={paginationConfig.pageSize}
    >
      {({ data, isLoading }) => <BlogMembersList isLoading={isLoading} blog={blog} members={data} />}
    </PaginatedList>
  );
};
