import { UiEmpty } from '@vkph/ui';
import React from 'react';

import { BlogWidgetComponent } from '~groups/components/blog/constants';

import { BlogWidgetNewContent } from '../BlogWidgetNewContent';

export const BlogWidgetNewContentAdapter: BlogWidgetComponent = (props) => {
  const { blog } = props;
  const { fileStorageSubFolders, id } = blog;
  const fileStorageId = fileStorageSubFolders?.files;

  if (!fileStorageId) {
    return <UiEmpty.Feed emptyMessage={{ header: 'Файловое хранилище группы не найдено' }} />;
  }

  return <BlogWidgetNewContent blogId={id} fileStorageId={fileStorageId} />;
};
