import { File } from '@vkph/components';
import { PostCompoundSearchViewProps } from '@vkph/modules';
import { UiTypography, UiTruncateMarkup, UiList } from '@vkph/ui';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { getFileStorageRouteParams } from '@vkph/common/store/filestorage/helpers';
import { FileStorageListEntryModel } from '@vkph/common/types/models';
import { getRoutePath, RouteNames } from '@vkph/common/utils';

interface Props extends Pick<PostCompoundSearchViewProps, 'compact'> {
  files: FileStorageListEntryModel[];
}

export const BlogWidgetCompoundFileList: FC<Props> = (props) => {
  const { files, compact } = props;
  const navigate = useNavigate();

  const onFileClick = (file: FileStorageListEntryModel) => {
    const routeParams = getFileStorageRouteParams(file);

    navigate(
      getRoutePath(RouteNames.GroupFile, {
        ...routeParams,
        fileId: file.id,
      }),
    );
  };

  return (
    <UiList
      split={false}
      dataSource={files}
      renderItem={(file) => (
        <UiList.Item hoverable style={{ padding: '6px 24px' }} onClick={() => onFileClick(file)}>
          <File
            title={
              <UiTypography.Text strong>
                <UiTruncateMarkup truncate lines={1}>
                  {file.name}
                </UiTruncateMarkup>
              </UiTypography.Text>
            }
            file={file}
            fileName={file.name}
            {...(compact ? { actions: null, stretch: false } : { stretch: true })}
          />
        </UiList.Item>
      )}
    />
  );
};
