import { RouterLink } from '@vkph/components';
import { UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import {
  BlogLastActivityMetaStorageObjectModel,
  BlogLastActivityRelatedObjectModel,
  BlogModel,
} from '@vkph/common/types/models';
import { getRoutePath, RouteNames } from '@vkph/common/utils';

interface BlogWidgetLastActivityListItemEntryProps
  extends BlogLastActivityRelatedObjectModel<BlogLastActivityMetaStorageObjectModel> {
  blog: BlogModel;
}

export const BlogWidgetLastActivityListItemStorageObject: FC<BlogWidgetLastActivityListItemEntryProps> = (
  props,
) => {
  const { meta, blog, isDeleted } = props;
  const { data, revision } = meta;
  const revisionText = revision ? `(Версия ${revision})` : '';
  const text = `${data.name} ${revisionText}`;

  const truncatedText = (
    <UiTruncateMarkup truncate lines={1} tooltipProps={{ title: text }}>
      {text}
    </UiTruncateMarkup>
  );

  return (
    <>
      {!isDeleted && (
        <RouterLink to={getRoutePath(RouteNames.GroupFile, { id: blog.id, fileId: data.id })}>
          {truncatedText}
        </RouterLink>
      )}
      {isDeleted && <UiTypography.Text strong>{truncatedText}</UiTypography.Text>}
    </>
  );
};
