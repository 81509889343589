import { TagMentionSearch, TagMentionSearchProps } from '@vkph/components';
import { UiButton, UiSpace } from '@vkph/ui';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

import { useQueryParams } from '@vkph/common/hooks';
import { BaseFieldParams } from '@vkph/common/types';
import { FileStorageEntryId, BlogId, TagRelationObjectType } from '@vkph/common/types/models';

import { BLOG_WIDGET_SEARCHER_INPUT_HEIGHT } from '../constants';

export interface BlogWidgetSearcherInputProps
  extends Omit<TagMentionSearchProps, 'size' | 'prefix' | 'onClick' | 'value' | 'onChange'> {
  blogId: BlogId;
  fileStorageId: FileStorageEntryId;
  onClick: (search: string) => void;
  onChange?: (value: string) => void;
}

export const BlogWidgetSearcherInput: FC<PropsWithChildren<BlogWidgetSearcherInputProps>> = (props) => {
  const { blogId, fileStorageId, children, onFocus, onClick, onChange, ...restProps } = props;
  const {
    queryParams: { search = '' },
    setQueryParams,
  } = useQueryParams<Pick<BaseFieldParams, 'search'>>();
  const MENTION_OVERLAY_INDEX = 4;

  const [inputValue, setInputValue] = useState<string>(search);

  const onSearchClick = () => setQueryParams({ search: inputValue });

  const onClearClick = () => {
    setInputValue('');
    setQueryParams({ search: '' });
  };

  const onChangeHandler = (value: string) => {
    setInputValue(value);
    onChange?.(value);
  };

  useEffect(() => {
    onChangeHandler(search);
  }, [search]);

  return (
    <UiSpace.Compact style={{ padding: '0 24px 16px 24px' }} block>
      <TagMentionSearch
        {...restProps}
        style={{
          suggestions: {
            zIndex: MENTION_OVERLAY_INDEX,
          },
        }}
        value={inputValue}
        onClear={onClearClick}
        onSearch={onSearchClick}
        onChange={onChangeHandler}
        onFocus={(e) => onFocus?.(e)}
        wrapperStyle={{ height: BLOG_WIDGET_SEARCHER_INPUT_HEIGHT, zIndex: MENTION_OVERLAY_INDEX }}
        filters={{
          serviceObjects: [
            {
              objectId: blogId,
              objectType: TagRelationObjectType.Blog,
            },
            {
              objectId: fileStorageId,
              objectType: TagRelationObjectType.StorageObject,
            },
          ],
        }}
      />
      <UiButton
        label="Найти"
        type="primary"
        onClick={onSearchClick}
        style={{ height: BLOG_WIDGET_SEARCHER_INPUT_HEIGHT, width: 140 }}
      />
    </UiSpace.Compact>
  );
};
