import { UiModal } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { getBlogMembersStorage } from '@vkph/common/store/blogs';
import { BlogModel, Role } from '@vkph/common/types/models';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import { BlogMembersPaginatedList } from '../../../members/paginated-list/BlogMembersPaginatedList';

type Props = {
  blog: BlogModel;
};

export const BlogWidgetNewParticipantsModal: FC<Props> = (props) => {
  const { blog } = props;
  const { id } = blog;

  const blogParticipantsStorage = useMemo(() => getBlogMembersStorage(id), [id]);
  const roles = useMemo(() => [Role.Admin, Role.Author, Role.Follower, Role.Moderator].join(','), []);

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Участники')} />
      </UiModal.Header>
      <UiModal.Content>
        <BlogMembersPaginatedList
          storage={blogParticipantsStorage}
          search=""
          roles={roles}
          blog={blog}
          paginationConfig={{ pageSize: 12 }}
        />
      </UiModal.Content>
    </>
  );
};
