import React, { FC } from 'react';

import {
  isBlogLastActivityRelatedObjectSomeOfTypes,
  isBlogLastActivityRelatedObjectType,
} from '@vkph/common/types';
import {
  BlogLastActivityMetaPostModel,
  BlogLastActivityMetaStorageObjectModel,
  BlogLastActivityRelatedObjectType,
} from '@vkph/common/types/models';

import { BlogWidgetLastActivityListItemProps } from '../BlogWidgetLastActivityListItem';
import { BlogWidgetLastActivityListItemEntry } from '../entry/BlogWidgetLastActivityListItemEntry';
import { BlogWidgetLastActivityListItemStorageObject } from '../storage-object/BlogWidgetLastActivityListItemStorageObject';

export const BlogWidgetLastActivityListItemWrapper: FC<
  Pick<BlogWidgetLastActivityListItemProps, 'object' | 'blog'>
> = (props) => {
  const { object, blog } = props;

  if (
    isBlogLastActivityRelatedObjectType<BlogLastActivityMetaPostModel>(
      object,
      BlogLastActivityRelatedObjectType.Entry,
    )
  ) {
    return <BlogWidgetLastActivityListItemEntry {...object} />;
  }

  if (
    isBlogLastActivityRelatedObjectSomeOfTypes<BlogLastActivityMetaStorageObjectModel>(object, [
      BlogLastActivityRelatedObjectType.StorageObject,
      BlogLastActivityRelatedObjectType.StorageObjectVersion,
    ])
  ) {
    return <BlogWidgetLastActivityListItemStorageObject {...object} blog={blog} />;
  }

  return null;
};
