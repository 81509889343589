import { AxiosResponse } from 'axios';

import { endpoints } from '@vkph/common/endpoints';
import { ProfileFullInfoParams } from '@vkph/common/store/profile/api';
import { SubstitutionItemForApi } from '@vkph/common/types';
import { api } from '@vkph/common/utils';

export type SubstitutionParams = ProfileFullInfoParams & { substitution: SubstitutionItemForApi[] };

export const putProfileSubstitution = <T = unknown>({
  userId,
  ...data
}: SubstitutionParams): Promise<AxiosResponse<T>> =>
  api.put({
    url: endpoints.profiles.userIdSubstitutions(userId),
    data: data.substitution,
  });
