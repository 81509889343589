import '@vkph/ui/index.scss';
import 'react-app-polyfill/stable';

import { Registry, useRegistry, withRegistry } from '@bem-react/di';
import { UiEmpty } from '@vkph/ui';
import React, { FC, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { useAbstractStorage, useLocalStorage } from '@vkph/common/hooks';
import { CurrentProfileProvider } from '@vkph/common/providers/current-profile';
import { SettingsConfigService } from '@vkph/common/providers/settings-config';
import { settingsAuthConfigStorage } from '@vkph/common/store/settings';
import { UserIdModel } from '@vkph/common/types/models';
import { Layout } from '@vkph/ui/components/layout';
import { SpaceProvider } from '@vkph/ui/providers/space';

import { ThemeService } from './services/theme-service';

const App: FC = () => {
  const { Router, AuthService } = useRegistry('app');
  const [userId, setUserId] = useState<UserIdModel>();

  const {
    data: settingsAuthData,
    loading: isSettingsAuthLoading,
    error: settingsAuthError,
  } = useAbstractStorage(settingsAuthConfigStorage.storage, {
    autoFetchAndRefetch: true,
  });
  const [authData, setAuthData] = useLocalStorage('settingsAuthData', settingsAuthData);

  useEffect(() => {
    if (settingsAuthData) {
      setAuthData(settingsAuthData);
    }
  }, [settingsAuthData]);

  if (settingsAuthError) {
    return (
      <UiEmpty.Feed
        emptyMessage={{
          header: 'Авторизация не может быть выполнена',
          description: 'Не удалось загрузить данные для авторизации',
        }}
      />
    );
  }

  if (isSettingsAuthLoading || !authData) {
    return <Layout.Loading />;
  }

  return (
    <BrowserRouter>
      <AuthService setUserId={setUserId}>
        <CurrentProfileProvider userId={userId}>
          <SettingsConfigService>
            <ThemeService>
              <SpaceProvider>
                <Router />
              </SpaceProvider>
            </ThemeService>
          </SettingsConfigService>
        </CurrentProfileProvider>
      </AuthService>
    </BrowserRouter>
  );
};

export const runApp = (registries: Registry[]) => {
  const AppWithRegistry = withRegistry(...registries)(App);

  const container = document.getElementById('root');

  if (!container) {
    return;
  }

  const root = createRoot(container);

  root.render(<AppWithRegistry />);
};
