import {
  UiBadge,
  UiButton,
  UiCol,
  UiDivider,
  UiEmpty,
  UiList,
  UiRow,
  UiSkeleton,
  UiSpace,
  UiTruncateMarkup,
  UiTypography,
} from '@vkph/ui';
import React, { useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { openGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';
import { getTagsListStorage } from '@vkph/common/store/tags';
import { OrderingParams } from '@vkph/common/types';
import { TagRelation, TagRelationObjectType } from '@vkph/common/types/models';
import { createArrayMock } from '@vkph/common/utils';

import { BlogWidgetComponent } from '../../constants';

export const BlogWidgetTopTags: BlogWidgetComponent = (props) => {
  const { blog } = props;
  const { id, fileStorageRootFolderId } = blog;

  const serviceObjects = useMemo<TagRelation[]>(() => {
    const result: TagRelation[] = [
      {
        objectId: id,
        objectType: TagRelationObjectType.Blog,
      },
    ];

    if (fileStorageRootFolderId) {
      result.push({
        objectId: fileStorageRootFolderId,
        objectType: TagRelationObjectType.StorageObject,
      });
    }

    return result;
  }, [id, fileStorageRootFolderId]);

  const { storage: tagsListStorage } = useMemo(getTagsListStorage, []);
  const {
    data: tagsData,
    error,
    loading,
  } = useAbstractStorage(tagsListStorage, {
    autoFetchParams: {
      serviceObjects,
      ordering: [OrderingParams.ObjectsCountAtDesc],
      pageSize: 10,
    },
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
  });

  const onShowAllTags = () => {
    openGlobalModal(GlobalModalNames.TagList, {
      relations: serviceObjects,
    });
  };

  const isTagsPending = loading;
  const isErrorOrNotFound = !isTagsPending && (tagsData.length === 0 || error);

  return (
    <>
      <UiDivider emptyMargin />

      {isTagsPending && (
        <UiSpace style={{ padding: '16px 24px' }} full direction="vertical" size={8}>
          {createArrayMock(10, (_, key) => (
            <UiSkeleton key={key} loading width="100%" height={20} />
          ))}
        </UiSpace>
      )}

      {isErrorOrNotFound && (
        <UiEmpty.Feed.Message emptyMessage={{ description: 'В группе нет материалов с тегами' }} />
      )}

      {!isErrorOrNotFound && !isTagsPending && (
        <UiSpace style={{ padding: '16px 24px 20px 24px' }} direction="vertical" size={20} full>
          <UiList
            split={false}
            dataSource={tagsData}
            renderItem={(tag) => {
              return (
                <UiList.Item noStyle>
                  <UiRow
                    wrap={false}
                    justify="space-between"
                    align="middle"
                    gutter={8}
                    style={{ padding: '8px 0' }}
                  >
                    <UiCol>
                      <UiTypography.Text>
                        <UiTruncateMarkup truncate tooltipProps={{ title: tag.name }}>
                          {tag.name}
                        </UiTruncateMarkup>
                      </UiTypography.Text>
                    </UiCol>
                    <UiCol>
                      <UiBadge count={tag.objectsCount} />
                    </UiCol>
                  </UiRow>
                </UiList.Item>
              );
            }}
          />

          <UiRow align="middle" justify="center">
            <UiButton type="link" label="Все теги" onClick={onShowAllTags} />
          </UiRow>
        </UiSpace>
      )}
    </>
  );
};
