import { Layout } from '@vkph/ui';
import { useStore } from 'effector-react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks/useAbstractStorage';
import { useLocalStorage } from '@vkph/common/hooks/useLocalStorage';
import { headerLogoImageStorage } from '@vkph/common/store/header-logo-image';
import { themeStorage } from '@vkph/common/store/theme';
import {
  themeOptionsDefault,
  ThemeOptions,
  ThemeProvider,
  OnChangeTheme,
  ThemeVariables,
} from '@vkph/ui/providers/theme';

export const ThemeService: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [userTheme, setUserTheme] = useLocalStorage<ThemeOptions>(
    themeOptionsDefault.varsPrefix,
    {} as ThemeOptions,
  );

  const { loading: isThemeLoading, error: themeError } = useAbstractStorage(themeStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const { data: headerLogoImageData } = useAbstractStorage(headerLogoImageStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const themeImages = useMemo<ThemeVariables>(() => {
    return Object.entries(headerLogoImageData).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value?.file };
    }, {});
  }, [headerLogoImageData]);

  const { colors, isUserColorsFilled } = useStore(themeStorage.themeState);

  const isUserThemeExist = !isEmpty(userTheme);
  const isThemeError = isEmpty(themeError);
  const isShowRouter = isUserThemeExist || isThemeError;

  const theme = useMemo<ThemeOptions>(() => {
    const themeDefault = !userTheme?.varsPrefix ? themeOptionsDefault : userTheme;
    const themeVariables = { ...themeDefault.variables };

    if (isUserColorsFilled) {
      colors.forEach(({ name, color }) => {
        themeVariables[name] = color;
      });
    }

    return { ...themeDefault, variables: themeVariables, images: themeImages };
  }, [userTheme, colors, isUserColorsFilled, themeImages]);

  const onChangeTheme = useCallback<OnChangeTheme>(
    ({ variables: themeVariables, ...rest }) => {
      setUserTheme({
        ...rest,
        variables: { ...userTheme?.variables, ...themeVariables },
        images: themeImages,
      });
    },
    [userTheme, themeImages],
  );

  useEffect(() => {
    if (isUserColorsFilled) {
      setUserTheme(theme);
    }
  }, [isUserColorsFilled]);

  useEffect(() => {
    let link = document.querySelector<HTMLLinkElement>("link[rel='icon']");

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }

    const faviconUrl =
      headerLogoImageData.portal_favicon?.file || headerLogoImageData.portal_favicon_default?.file;

    if (faviconUrl) {
      link.href = `${faviconUrl}?v=${Date.now()}`;
    }
  }, [headerLogoImageData.portal_favicon?.file, headerLogoImageData.portal_favicon_default?.file]);

  return (
    <ThemeProvider theme={theme} onChange={onChangeTheme}>
      {isShowRouter && children}
      {!isUserThemeExist && <Layout.Loading spinning={isThemeLoading} />}
    </ThemeProvider>
  );
};
