import { UiTabItem, UiTabs, UiTabsProps } from '@vkph/ui';
import React, { FC } from 'react';

import { useQueryParams } from '@vkph/common/hooks';
import {
  BlogId,
  SearchAllCategory,
  SearchCategoryType,
  SearchSingleCategory,
} from '@vkph/common/types/models';
import { SearchCategory } from '@vkph/modules/store/search/advanced-search';

const BLOG_WIDGET_SEARCH_CATEGORY_TABS: UiTabItem<SearchCategoryType>[] = [
  {
    key: SearchAllCategory.All,
    label: 'Искать везде',
  },
  {
    key: SearchSingleCategory.Post,
    label: 'Посты',
  },
  {
    key: SearchSingleCategory.Files,
    label: 'Файлы',
  },
];

type Props = {
  blogId: BlogId;
  tabBarExtraContent: UiTabsProps['tabBarExtraContent'];
};

export const BlogWidgetSearcherContentTabs: FC<Props> = (props) => {
  const { tabBarExtraContent } = props;
  const {
    queryParams: { category = SearchCategory.All },
    setQueryParams,
  } = useQueryParams<{
    category: SearchCategoryType;
    query: string;
  }>();

  return (
    <UiTabs
      defaultActiveKey={category}
      activeKey={category}
      tabBarExtraContent={tabBarExtraContent}
      items={BLOG_WIDGET_SEARCH_CATEGORY_TABS}
      onChange={(key) => {
        setQueryParams({ category: key as SearchCategoryType });
      }}
    />
  );
};
