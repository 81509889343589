import { UiUser } from '@vkph/components';
import { UiDivider, UiEmpty, UiList, UiSpace } from '@vkph/ui';
import React, { useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getBlogLastActivityStorage } from '@vkph/common/store/blogs';
import { BlogLastActivityModel } from '@vkph/common/types/models';
import { createArrayMock } from '@vkph/common/utils';

import { BlogWidgetComponent } from '../../constants';
import { BlogWidgetLastActivityListItem } from './list-item/BlogWidgetLastActivityListItem';

export const BlogWidgetLastActivity: BlogWidgetComponent = (props) => {
  const { blog } = props;
  const blogLastActivityStorage = useMemo(getBlogLastActivityStorage, []);

  const {
    data: blogLastActivityData,
    loading: isLoading,
    error,
  } = useAbstractStorage(blogLastActivityStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { blogId: blog.id, pageSize: 5 },
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  const isHasActor = (item: BlogLastActivityModel): item is Required<BlogLastActivityModel> => {
    return Boolean(item.actor);
  };

  const filteredBlogLastActivityData = useMemo<Required<BlogLastActivityModel>[]>(() => {
    return blogLastActivityData.filter(isHasActor);
  }, [blogLastActivityData]);

  return (
    <>
      <UiDivider emptyMargin />

      {isLoading && (
        <UiSpace size={16} direction="vertical" style={{ padding: '16px 24px' }}>
          {createArrayMock(3, (_, key) => (
            <UiUser.Info.Skeleton size="middle" key={key} />
          ))}
        </UiSpace>
      )}
      {!error && !isLoading && (
        <UiList
          style={{ marginTop: 16, paddingBottom: 16 }}
          dataSource={filteredBlogLastActivityData}
          renderItem={(item) => <BlogWidgetLastActivityListItem {...item} blog={blog} />}
        />
      )}
      {error && !isLoading && (
        <UiEmpty.Feed emptyMessage={{ header: 'Ошибка получения последних действий' }} />
      )}
    </>
  );
};
