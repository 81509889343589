import { LinkListItem } from '@vkph/components';
import { UiRow, UiList, UiCol } from '@vkph/ui';
import React, { FC } from 'react';

import { Blog } from '@vkph/common/types';
import { BlogModel, PermissionsV2Enum, Role } from '@vkph/common/types/models';
import {
  createArrayMock,
  authService,
  getFullNameWithoutPatronymic,
  getRoutePath,
  RouteNames,
  capitalizeFirstLetter,
} from '@vkph/common/utils';
import { RoleNames } from '~groups/store/blog/constants';

import { MembersListActions } from './actions/MembersListActions';

const COMMON_ROLES = [Role.NotFollower, Role.Follower];
const MOCKS_COUNT = 7;
const membersListMocks = createArrayMock(MOCKS_COUNT, (_, id) => id);

type Props = {
  members: Blog.Member[];
  blog: BlogModel;
  isLoading: boolean;
};

export const BlogMembersList: FC<Props> = (props) => {
  const { members, blog, isLoading } = props;
  const currentUserId = authService.getCurrentUserId();

  const ParticipantMember: FC<{ member: Blog.Member }> = (memberProps) => {
    const { member } = memberProps;
    const rolePostfix = !COMMON_ROLES.includes(member.role)
      ? `(${capitalizeFirstLetter(RoleNames[member.role])})`
      : '';
    const title = `${getFullNameWithoutPatronymic(member)} ${rolePostfix}`;
    const isHasActions =
      blog?.permissionsV2?.[PermissionsV2Enum.RemoveMembers] && member.keycloakId !== currentUserId;

    const memberActions = isHasActions && <MembersListActions blogId={blog.id} blogMember={member} />;

    return (
      <LinkListItem
        to={getRoutePath(RouteNames.Profile, { id: member.keycloakId })}
        suffixedChildren={memberActions}
        avatarProps={{ src: member.avatar }}
        isActive={member.isActive}
        title={title}
        subtitle={member.division}
      />
    );
  };

  return (
    <UiRow style={{ padding: '24px 0' }}>
      <UiCol span={24}>
        {isLoading && (
          <UiList
            dataSource={membersListMocks}
            renderItem={() => (
              <UiList.Item noStyle>
                <LinkListItem.Skeleton />
              </UiList.Item>
            )}
          />
        )}

        {!isLoading && (
          <UiList
            dataSource={members}
            renderItem={(member) => (
              <UiList.Item noStyle>
                <ParticipantMember member={member} />
              </UiList.Item>
            )}
          />
        )}
      </UiCol>
    </UiRow>
  );
};
