import { Role } from '@vkph/common/types/models';

export const RoleNames = {
  [Role.SuperAdmin]: 'супер-администратор',
  [Role.Admin]: 'администратор',
  [Role.Author]: 'редактор',
  [Role.Moderator]: 'модератор',
  [Role.Follower]: 'подписчик',
  [Role.NotFollower]: 'не подписчик',
};

export const RolesManagers = [Role.Author, Role.Admin, Role.SuperAdmin, Role.Moderator];
