import { UiButton, UiButtonProps, UiCard, UiEmpty, UiPaginationConfig, UiSpace } from '@vkph/ui';
import { stringify } from 'query-string';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAbstractStorage, useQueryParams } from '@vkph/common/hooks';
import { GetFileStorageSearchListStorage } from '@vkph/common/store/filestorage';
import { OrderingParams } from '@vkph/common/types/api';
import {
  BlogId,
  SearchAllCategory,
  SearchCategoryType,
  SearchSingleCategory,
} from '@vkph/common/types/models';
import { getRoutePath, RouteNames } from '@vkph/common/utils';
import { SearchCategory } from '@vkph/modules/store/search/advanced-search';
import { GetBlogPostsStorage } from '~groups/store/blog';

import { BlogWidgetCompound } from '../../../compound/BlogWidgetCompound';
import { BlogWidgetSearcherContentResultsWrapper } from './wrapper/BlogWidgetSearcherContentResultsWrapper';

interface Props extends Pick<UiPaginationConfig, 'pageSize'> {
  blogId: BlogId;
  postsStorage: GetBlogPostsStorage;
  filesStorage: GetFileStorageSearchListStorage;
  compact?: boolean;
}

export const BlogWidgetSearcherContentResults: FC<Props> = (props) => {
  const { blogId, postsStorage, filesStorage, compact = false, pageSize = 3 } = props;
  const navigate = useNavigate();

  const {
    queryParams: { category = SearchCategory.All, search = '' },
  } = useQueryParams<{
    category: SearchCategoryType;
    search: string;
  }>();

  const isSearchCategory = (categoryTypes: SearchCategoryType[]) => {
    return categoryTypes.includes(category);
  };

  const isHasSearch = search.length > 1;
  const isShowPosts = isHasSearch && isSearchCategory([SearchAllCategory.All, SearchSingleCategory.Post]);
  const isShowFiles = isHasSearch && isSearchCategory([SearchAllCategory.All, SearchSingleCategory.Files]);

  const {
    data: posts,
    loading: postsLoading,
    pagination: { count: postsCount },
  } = useAbstractStorage(postsStorage.storage, {
    autoFetchAndRefetch: isShowPosts,
    autoFetchParams: {
      pageSize,
      search: search || undefined,
    },
    cancelPendingRequestOnUnmount: true,
  });

  const {
    data: files,
    loading: filesLoading,
    pagination: { count: filesCount },
  } = useAbstractStorage(filesStorage.storage, {
    autoFetchAndRefetch: isShowFiles,
    autoFetchParams: {
      pageNumber: 1,
      pageSize,
      search: search || undefined,
      ordering: [OrderingParams.CreatedAtDesc],
    },
    cancelPendingRequestOnUnmount: true,
  });

  const isEmptyResults = !isHasSearch || (isShowPosts && !posts.length && isShowFiles && !files.length);
  const isLoading = filesLoading || postsLoading;

  const onOpenSearch = (categoryType: SearchCategoryType) => {
    navigate({
      pathname: getRoutePath(RouteNames.GroupSearch, { id: blogId }),
      search: stringify({ category: categoryType, search }),
    });
  };

  const hasMorePosts = postsCount && postsCount > pageSize;
  const hasMoreFiles = filesCount && filesCount > pageSize;

  const showAllButtonProps: UiButtonProps = {
    type: 'link',
    label: 'Все',
  };

  const resultsNode = (
    <>
      {isShowPosts && (
        <BlogWidgetSearcherContentResultsWrapper
          compact={compact}
          category={category}
          isEmpty={!posts.length}
          isLoading={postsLoading}
        >
          <BlogWidgetCompound.TabSection
            title="Посты"
            recordCount={postsCount}
            compact={compact}
            extra={
              compact && hasMorePosts ? (
                <UiButton onClick={() => onOpenSearch(SearchSingleCategory.Post)} {...showAllButtonProps} />
              ) : undefined
            }
          >
            <BlogWidgetCompound.PostList posts={posts} compact={compact} />
          </BlogWidgetCompound.TabSection>
        </BlogWidgetSearcherContentResultsWrapper>
      )}

      {isShowFiles && (
        <BlogWidgetSearcherContentResultsWrapper
          compact={compact}
          category={category}
          isEmpty={!files.length}
          isLoading={filesLoading}
        >
          <BlogWidgetCompound.TabSection
            title="Файлы"
            recordCount={filesCount}
            compact={compact}
            extra={
              compact && hasMoreFiles ? (
                <UiButton onClick={() => onOpenSearch(SearchSingleCategory.Files)} {...showAllButtonProps} />
              ) : undefined
            }
          >
            <BlogWidgetCompound.FileList files={files} compact={compact} />
          </BlogWidgetCompound.TabSection>
        </BlogWidgetSearcherContentResultsWrapper>
      )}

      {!compact && isEmptyResults && !isLoading && (
        <UiCard size="default" emptyPadding>
          <UiEmpty.Feed
            emptyMessage={{
              header: 'Ничего не найдено',
              description: 'Попробуйте сформулировать запрос по-другому',
            }}
          />
        </UiCard>
      )}
    </>
  );

  return (
    <>
      {compact && resultsNode}
      {!compact && (
        <UiSpace size={20} direction="vertical" full>
          {resultsNode}
        </UiSpace>
      )}
    </>
  );
};
