import { DynamicRenderComponent } from '@vkph/modules';
import React, { FC } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

type DynamicRenderRouteProps = {
  routeModuleMap: Record<string, string>;
};

export const DynamicRenderRoute: FC<DynamicRenderRouteProps> = (props) => {
  const { routeModuleMap } = props;
  const location = useLocation();
  const { ...payload } = location.state || {};
  const matchedRoute = matchRoutes(
    Object.keys(routeModuleMap).map((route) => ({ path: route })),
    location,
  );

  if (!matchedRoute || !matchedRoute.length) return null;

  const modulePath = routeModuleMap[matchedRoute[0].route.path];
  const moduleProps = { ...payload };

  return <DynamicRenderComponent modulePath={modulePath} moduleProps={moduleProps} />;
};
