import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '@vkph/common/endpoints';
import { UserIdParams } from '@vkph/common/store/profile';
import { DictPaginated } from '@vkph/common/types';
import { GroupDivisionModel, ProfileUserSettings, UserJobModel } from '@vkph/common/types/models';
import { abstractStorageFactory } from '@vkph/common/utils';

import {
  profileUserAvatarUpload,
  ProfileUserAvatarUploadParams,
  getProfileUserSettings,
  postProfileUserSettings,
} from './api';

type ProfileUserAvatarUploadResponse = {
  large: string;
  medium: string;
  small: string;
};

export const uploadProfileUserAvatarStorage = () => {
  const uploadProfileUserAvatarEffect = createEffect<ProfileUserAvatarUploadParams, string, AxiosError>({
    handler: (params) =>
      profileUserAvatarUpload<ProfileUserAvatarUploadResponse>(params).then(
        (response) => response.data.medium,
      ),
  });

  return { uploadProfileUserAvatarEffect };
};

export const getProfileJobStorage = ({ userId }: UserIdParams) => {
  const storage = abstractStorageFactory<UserJobModel, UserJobModel, null>({
    endpointBuilder: () => endpoints.profiles.job(userId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getProfileUserSettingsStorage = () => {
  const storage = abstractStorageFactory<ProfileUserSettings, ProfileUserSettings, null>({
    endpointBuilder: getProfileUserSettings,
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const updateProfileUserSettingsEffect = createEffect<
    Partial<ProfileUserSettings>,
    ProfileUserSettings,
    AxiosError
  >((params) => postProfileUserSettings<ProfileUserSettings>(params).then(({ data }) => data));

  storage.store.on(updateProfileUserSettingsEffect.doneData, (state, settings) =>
    state.data ? { ...state, data: { ...state.data, ...settings } } : state,
  );

  return {
    storage,
    updateProfileUserSettingsEffect,
  };
};

export const getProfileSubdivisionsHeadStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GroupDivisionModel>,
    GroupDivisionModel[],
    GroupDivisionModel[],
    UserIdParams
  >({
    endpointBuilder: endpoints.orgstructure.search,
    requestMethod: 'post',
    dataBuilder: ({ userId }) => ({
      groupType: 'group',
      attributes: {
        groupLeader: userId,
      },
    }),
    dataMapper: ({ items }) => items,
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export type GetProfileSubdivisionsHeadStorage = ReturnType<typeof getProfileSubdivisionsHeadStorage>;
