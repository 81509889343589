import { RouterLink } from '@vkph/components';
import { UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import { useLongreadLink } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types';
import {
  BlogLastActivityMetaPostModel,
  BlogLastActivityRelatedObjectModel,
  PostTypes,
} from '@vkph/common/types/models';
import { getPlainTextFromHtml, parseMentionsToPlainText } from '@vkph/common/utils';

export const BlogWidgetLastActivityListItemEntry: FC<
  BlogLastActivityRelatedObjectModel<BlogLastActivityMetaPostModel>
> = (props) => {
  const { meta, isDeleted } = props;
  const { data } = meta;
  const plainTextContent = data?.body?.data && getPlainTextFromHtml(data.body.data);
  const text = (plainTextContent && parseMentionsToPlainText(plainTextContent)) || 'Пост без текста';

  const linkProps = useLongreadLink({ type: LongreadTypes.Post, id: data.id, postType: PostTypes.Entry });

  const truncatedText = (
    <UiTruncateMarkup truncate lines={1} tooltipProps={{ title: text }}>
      {text}
    </UiTruncateMarkup>
  );

  return (
    <>
      {!isDeleted && <RouterLink {...linkProps}>{truncatedText}</RouterLink>}
      {isDeleted && <UiTypography.Text strong>{truncatedText}</UiTypography.Text>}
    </>
  );
};
